<template>
    <div>
        <page-title :icon=icon :heading="$t('generic.lang_nav_systemSettings')" :subheading="$t('generic.lang_nav_systemSettings')"></page-title>
        <div class="app-main__inner">
            <RegistrationData></RegistrationData>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import RegistrationData from "../../../components/settings/sysetmdata/SystemSettingsComponent.vue";

    export default {
        components: {
            PageTitle,
            RegistrationData
        },

        data: () => ({
            icon: 'pe-7s-settings icon-gradient bg-tempting-azure',
        })
    }
</script>