<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card :disabled="loading" elevation="0" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="8">
            <v-textarea outlined
                        @focus="showTouchKeyboard"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        v-model="ecText"
                        :label="this.$t('settings.lang_freeTextEC')"
                        autocomplete="off"
                        dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-checkbox v-model="openCashDrawer" :label="$t('settings.lang_printerManagerOpenDrawerCardPayment')"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveSettings" :disabled="loading" :loading="loading">{{ $t('generic.lang_save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";

export default {
  name: "ECSettingsComponent",

  mixins: [mixin],

  data() {
    return {
      loading: false,

      openCashDrawer: false,
      ecText: ""
    }
  },

  mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.ECPAYMENT.GET).then((res) => {
        if(res.data.success) {
          this.openCashDrawer = res.data.openCashDrawer === 1;
          this.ecText = res.data.ecText;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.ECPAYMENT.UPDATE, {
        openCashDrawer: this.openCashDrawer === true ? 1 : 0,
        ecText: this.ecText
      }).then((res) => {
        if(!res.data.success)
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            color: "error"
          });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>