<template>
  <v-container class="pa-0" fluid>
    <v-card elevation="0">
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-subheader>{{ $t('settings.lang_defaultCurrency') }}:</v-subheader>
          </v-col>
          <v-col cols="6">
            <v-select
                v-model="selectedCurrency"
                :items="currency"
                item-text="Name"
                item-value="id"
                :label="$t('accounting.lang_set_currency')"
                outlined
                dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-checkbox v-model="multiCurrency" :label="$t('settings.lang_activateMulticurrency')"></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loadingSave" :disabled="loadingSave" color="success" @click="saveSettings">
          {{ $t('generic.lang_save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "CashierCurrencyAndRoundingComponent",
  data() {
    return {
      loading: false,
      loadingSave: false,
      selectedCurrency: null,
      currency: [
        {Name: 'EUR', id: 1},
        {Name: 'DOL', id: 2},
        {Name: 'CENT', id: 3},
      ],
      multiCurrency: true,
    }
  },
  methods: {
    saveSettings() {
      this.loadingSave = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CURRENCYANDROUNDING.UPDATE, {
        selectedCurrency: this.selectedCurrency,
        multiCurrency: this.multiCurrency,
      }).then((res) => {

        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSave = false;
      })
    },
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CURRENCYANDROUNDING.GET).then((res) => {
        if (res.data.success) {
          this.selectedCurrency = res.data.selectedCurrency;
          this.multiCurrency = res.data.multiCurrency;
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.getSettings();
  }
}
</script>

<style scoped>

</style>
