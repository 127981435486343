<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card :disabled="loading" elevation="0" >
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="6" lg="6" align="end">
            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading" :loading="loading"
                          :label="$t('settings.lang_paypalCLientId')"
                          @focus="showTouchKeyboard"
                          autocomplete="off"
                          dense
                          outlined
                          required
                          v-model="paypalID"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading" :loading="loading"
                          :label="$t('settings.lang_paypalSecret')"
                          @focus="showTouchKeyboard"
                          autocomplete="off"
                          dense
                          outlined
                          type="password"
                          v-model="paypalSecret"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="ma-0"/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveSettings" :disabled="loading" :loading="loading">{{ $t('generic.lang_save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
name: "PaypalSettingsComponent",
  mixins: [mixin],

  data() {
    return {
      loading: false,
      paypalID:'',
      paypalSecret:'',

    }
  },

  mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.PAYPAL.GET).then((res) => {
        if(res.data) {
          this.paypalID=res.data.payPalClientID;
          this.paypalSecret=res.data.payPalSecret;
        }else{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.PAYPAL.UPDATE, {
        payPalClientID: this.paypalID,
        payPalSecret: this.paypalSecret,
      }).then((res) => {
        if(res.data){
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "error"
          });
        }else{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>