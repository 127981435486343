<template>

  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-subheader>{{ $t('settings.lang_decimalPlaces') }}:</v-subheader>
      </v-col>
      <v-col
          cols="6"
      >
        <v-text-field
            :label="$t('settings.lang_roundPlaces')"
            v-model="places"
            type="number"
            :min="0"
            outlined
            dense
            :loading="this.loading"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-subheader>{{ $t('settings.lang_roundingTaxCalc') }}:</v-subheader>
      </v-col>
      <v-col cols="6">
        <v-select
            v-model="selectedRounds"
            :items="rounds"
            item-text="Name"
            item-value="id"
            outlined
            :loading="this.loading"
            dense
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-checkbox :loading="this.loading" v-if="this.$store.getters['permissions/checkModule'](4)"
                    v-model="factureGrossPrice"
                    :label="$t('accounting.lang_factureGrossPrice')"></v-checkbox>
      </v-col>


      <v-col cols="12" class="text-right">
        <v-spacer></v-spacer>
        <v-btn depressed :loading="loadingSave || loading" :disabled="loadingSave" color="success"
               @click="save">
          {{ $t('generic.lang_save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "GlobalBillingOptionComponent",
  data() {
    return {
      loading: false,
      factureGrossPrice: false,
      places: "",
      loadingSave: false,
      selectedCurrency: null,
      currency: [
        {Name: 'EUR', id: 1},
        {Name: 'DOL', id: 2},
        {Name: 'CENT', id: 3},

      ],
      selectedRounds: null,
      rounds: [
        {Name: this.$t('settings.lang_LineRounding'), id: 1},
        {Name: this.$t('settings.lang_roundTotal'), id: 2},
      ],
    }
  },
  methods: {
    save() {
      this.loadingSave = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.BILLING_OPTIONS.UPDATE, {
        factureGrossPrice: this.factureGrossPrice,
        roundingPlaces: this.places,
        selectedRoundMethod: this.selectedRounds,
      }).then((res) => {

        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSave = false;
      })
    },
    getOptions() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.BILLING_OPTIONS.GET).then((res) => {
        if (res.data.success) {
          this.factureGrossPrice = res.data.factureGrossPrice;
          this.selectedRounds = res.data.selectedRoundMethod;
          this.places = res.data.roundingPlaces;
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.getOptions();
  }

}
</script>

<style scoped>

</style>
