<template>
  <div>
    <v-card flat tile class="ma-0" :loading="loading">
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col lg="8" md="6" sm="12" cols="12">
              <v-textarea outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="freeText"
                          :label="this.$t('settings.lang_freeTextCash')"
                          autocomplete="off"
                          dense
              >
              </v-textarea>
            </v-col>
            <v-col lg="4" md="6" sm="12" cols="12">
              <v-checkbox v-model="enabled" :label="this.$t('generic.lang_paymrntOptGiven')"></v-checkbox>
            </v-col>
          </v-row>

          <v-row wrap>
            <v-col cols="12" sm="6" md="6" v-for="(currency, index) in currency[currencyIMG].scheine" :key="index">
              <v-card flat @click="toggle(currency)" ripple outlined :class="{'bg-success':currency.status}">
                <v-img contain :src="currency.image" class="ma-3" height="100"/>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top: 20px;">
            <v-col cols="6" sm="6" md="6" v-for="(currency, index) in currency[currencyIMG].muenzen" :key="index">
              <v-card flat @click="toggle(currency)" ripple :class="{'bg-success':currency.status}" outlined>
                <v-img contain :src="currency.image" height="50"/>
              </v-card>
            </v-col>
          </v-row>

          <v-divider class="ma-0 pa-0"/>
          <v-row no-gutters justify-md="end" justify="center">
            <v-btn color="success" large :loading="loading" @click="update()">
              {{ $t('generic.lang_apply') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import mixin from '../../../../mixins/KeyboardMixIns'
import {Events} from "../../../../plugins/events";
import {ENDPOINTS} from "@/config";

export default {
  name: "CashPaymentComponent",
  components: {},
  mixins: [mixin],
  computed:{
    currencyIMG(){
      return this.$store.getters['settings/getSettingValue']('geoloc_systemcountry') === "ma"?"mad":"euro";
    }
  },
  data() {
    return {
      freeText: null,
      loading: false,
      enabled: false,
      currency: {
        euro: {
          muenzen: [
            {
              value: 0.01,
              image: require("@/assets/images/currency/euro/1cents.png"),
              status: false,
            },
            {
              value: 0.02,
              image: require("@/assets/images/currency/euro/2cents.png"),
              status: false,
            },
            {
              value: 0.05,
              image: require("@/assets/images/currency/euro/5cents.png"),
              status: false,
            },
            {
              value: 0.1,
              image: require("@/assets/images/currency/euro/10cents.png"),
              status: false,
            },
            {
              value: 0.2,
              image: require("@/assets/images/currency/euro/20cents.png"),
              status: false,
            },
            {
              value: 0.5,
              image: require("@/assets/images/currency/euro/50cents.png"),
              status: false,
            },
            {
              value: 1,
              image: require("@/assets/images/currency/euro/1.png"),
              status: false,
            },
            {
              value: 2,
              image: require("@/assets/images/currency/euro/2.png"),
              status: false,
            },
          ],
          scheine: [
            {
              value: 5,
              image: require("@/assets/images/currency/euro/5.png"),
              status: false,
            },
            {
              value: 10,
              image: require("@/assets/images/currency/euro/10.png"),
              status: false,
            },
            {
              value: 20,
              image: require("@/assets/images/currency/euro/20.png"),
              status: false,
            },
            {
              value: 50,
              image: require("@/assets/images/currency/euro/50.png"),
              status: false,
            },
            {
              value: 100,
              image: require("@/assets/images/currency/euro/100.png"),
              status: false,
            }
          ]
        },
        mad: {
          muenzen: [
            {
              value: 0.5,
              image: require("@/assets/images/currency/dirham/0dot5.png"),
              status: false,
            },
            {
              value: 1,
              image: require("@/assets/images/currency/dirham/1.png"),
              status: false,
            },
            {
              value: 2,
              image: require("@/assets/images/currency/dirham/2.png"),
              status: false,
            },
            {
              value: 5,
              image: require("@/assets/images/currency/dirham/5.png"),
              status: false,
            },
            {
              value: 10,
              image: require("@/assets/images/currency/dirham/10.png"),
              status: false,
            },
          ],
          scheine: [
            {
              value: 20,
              image: require("@/assets/images/currency/dirham/20.png"),
              status: false,
            },
            {
              value: 50,
              image: require("@/assets/images/currency/dirham/50.png"),
              status: false,
            },
            {
              value: 100,
              image: require("@/assets/images/currency/dirham/100.png"),
              status: false,
            },
            {
              value: 200,
              image: require("@/assets/images/currency/dirham/200.png"),
              status: false,
            }
          ]
        }
      },
    }
  },
  methods: {
    toggle(currency) {
      currency.status = !currency.status;
    },
    getData() {
      let self = this;
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.CASHPAYMENT.GET)
          .then(res => {

            if (res.data.status === "SUCCESS") {

              this.enabled = res.data.header.payAppropriately;
              this.freeText = res.data.header.cashPaymentText;
              let currencies = JSON.parse(res.data.header.cashPaymentLayout);

              if (currencies.hasOwnProperty(this.currencyIMG)) {
                if (Object.keys(currencies[this.currencyIMG]).length > 0) {
                  self.currency[this.currencyIMG] = currencies[this.currencyIMG];
                }

                // currencies[this.currencyIMG].muenzen.forEach((el, index) => {
                //   self.currency[this.currencyIMG].muenzen[index].status = el.status
                //   self.currency[this.currencyIMG].muenzen[index].image = el.image
                //   self.currency[this.currencyIMG].muenzen[index].value = el.value
                // })

                // currencies[this.currencyIMG].scheine.forEach((el, index) => {
                //   self.currency[this.currencyIMG].scheine[index].status = el.status
                //   self.currency[this.currencyIMG].scheine[index].image = el.image
                //   self.currency[this.currencyIMG].scheine[index].value = el.value
                // })
              }
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }

          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_error'),
          color: "error"
        });
        //console.log(err)
      }).finally(() => {
        this.loading = false;
      })
    },
    update() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.PAYMENT.CASHPAYMENT.UPDATE, {
        payAppropriately: (this.enabled) ? 1 : 0,
        cashPaymentText: this.freeText,
        cashPaymentLayout: this.currency,
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_updatedSuccessfully'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_error'),
            color: "error undefined"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_error'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.getData();
  }

}
</script>

<style scoped>

</style>
