<template>
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card card shadow-sm pa-1">
        <div>
            <v-progress-linear
                    class="ma-0"
                    color="primary"
                    indeterminate
                    v-if="this.loading"
            ></v-progress-linear>
        </div>
        <v-row no-gutters v-if="!this.loading">
            <v-col lg="12" v-for="(item,n) in footers" :key="n">
                <div :class="[$vuetify.theme.dark? 'dark-bg' : '']" class="card mb-3 shadow-sm border-grey border ma-1">
                    <div v-if="item.editing" class="card-body pa-1">
                        <quill-editor :options="quillOptions" v-model="item.text"/>
                    </div>
                    <div v-else class="card-body pa-1 border border-grey ma-1" v-html="item.text">

                    </div>
                    <div class="card-footer pa-1">
                        <v-row justify="space-between" no-gutters>
                            <div class="align-self-center">
                                <v-btn class="btn bg-info white--text mt-0 mb-0"
                                       large @click="enableEditing(item)">
                                    <v-icon v-if="!item.editing">mdi-pencil</v-icon>
                                    <v-icon v-if="item.editing">mdi-floppy</v-icon>
                                </v-btn>
                                <v-btn class="btn bg-danger white--text mt-0 mb-0"
                                       large @click="deleteFooter(n)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </div>

                            <v-switch v-model="item.enabled" :label="$t('generic.lang_enable')"></v-switch>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider class="my-1"/>
        <div class="card-footer transparent text-right pa-0 ma-0">
            <v-row justify="end" class="px-2" no-gutters>
                <v-btn :disabled="!(this.footers.length<5) || loading" class="mx-2" elevation="1"
                        rounded large color="info" @click="addFooter()" >
                    <v-icon class="white--text">mdi-plus</v-icon>
                </v-btn>
                <v-btn :loading="loading"  class="mx-2" elevation="1"
                       rounded large color="success" @click="update()">
                    <v-icon class="white--text">mdi-floppy</v-icon>
                </v-btn>
                <v-btn :disabled="loading" class="mx-2" elevation="1" rounded large color="purple">
                    <v-icon class="white--text">mdi-eye</v-icon>
                </v-btn>
            </v-row>
        </div>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import {quillEditor} from 'vue-quill-editor'
    import {Events} from "../../../../plugins/events";
    import {ENDPOINTS} from "@/config";

    export default {
        name: "RecipeFootersComponent",
        components:{
            quillEditor,
        },
        data(){
            return {
                max:5,
                loading:false,
                footers:[],
                quillOptions:{
                    modules: {
                        toolbar: [
                            ['bold','italic','underline','strike'],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            [{ 'align': [] }],
                            [{ 'font': [] }],
                        ],

                    },
                }
            }
        },
        methods:{
            getData(){
                this.loading=true;
                this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.GET,{
                    updatePart:'invoiceFooter',
                }).then(res=>{
                  if(res.data.status === 'SUCCESS'){
                    let footers=[]
                    if(res.data.footerObj && res.data.footerObj.length>0){
                      res.data.footerObj.forEach((footer,index)=>{
                        footers.push(footer)
                        footers[index].editing=false;
                      })
                      this.footers=footers
                    }else{
                      Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_noDataToDisplay'),
                        color: "warning"
                      });
                    }
                    }else{
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                    this.loading=false;
                    }).catch(err=>{
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                    this.loading=false;
                })
            },
            update(){
                let footers=[];
                this.footers.forEach(el=>{
                  footers.push({
                    text:el.text.toString(),
                    enabled:el.enabled,
                  })
                  el.editing=false;
                })
                this.loading=true;
                this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.UPDATE,{
                    updatePart:'invoiceFooter',
                    invoiceLayoutFooter:footers,
                }).then(res=>{
                    this.loading=false;
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                    }).catch(err=>{

                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                    this.loading=false;
                })
            },
            enableEditing(item){
              item.editing=!item.editing;
            },
            deleteFooter(index){
                this.footers.splice(index,1)
            },
            addFooter(){
                if(this.footers.length<5){
                    this.footers.push({
                        editing:true,
                        text:"Footer"+this.footers.length+1,
                        enabled:false,
                    })
                }
            }
        },
        mounted() {
            this.getData();
        }

    }
</script>

<style scoped>

</style>
