<template>
  <v-container fluid>

    <v-progress-circular indeterminate color="primary" v-if="this.loading"/>
    <!-- creation form -->
    <v-row v-else-if="!this.loading && !this.hasDomains">
      <v-col class="text-center" cols="12">
        <h2 class="mx-auto mt-3">
          {{$t('settings.lang_generateServiceSubdomain')}}
        </h2>
        <p class="text-muted text-justify">
          {{$t('settings.lang_generateServiceSubdomainBody')}}
        </p>
      </v-col>
      <v-col cols="12">
        <v-alert color="info" type="info" outlined>
          {{$t('settings.lang_generateServiceSubdomainHint')}}
        </v-alert>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-form ref="form" lazy-validation>
          <v-text-field v-model="form.subDomain" :rules="[ v => !!v , v => this.validDomain(v)]"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @focus="showTouchKeyboard" outlined dense :label="$t('settings.lang_subdomain')"></v-text-field>
        </v-form>
      </v-col>


      <v-col cols="12" class="pt-0">
        <v-btn :disabled="this.creation_loading" :loading="this.creation_loading" @click="create" block color="primary"
               depressed large class="mx-0">
          {{ this.$t("generic.lang_create") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col v-for="(domain , i) in domains" :key="i" cols="12">
        <v-text-field disabled outlined readonly :label="domain.service" hide-details
                      :value="domain.domain">

          <template v-slot:append>
            <v-icon @click="openDomain(domain.domain)" color="primary">open_in_new</v-icon>

            <v-icon @click="copyDomain(domain.domain)" class="mx-3">content_copy</v-icon>

          </template>
        </v-text-field>
      </v-col>
    </v-row>


    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                          :layout="touchKeyboard.layout" :options="touchKeyboard.options"/>
    </div>
  </v-container>
</template>

<script>
import mixin from "../../../../mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Template from "@/components/settings/customerdisplay/Template";

export default {
  name: "SubdomainGeneratorComponent",
  components: {Template},
  mixins: [mixin],
  data() {
    return {
      form: {
        subDomain: ""
      },
      creation_loading: false,
      loading: false,
      hasDomains: false,
      domains: null
    }
  },
  computed: {
    validDomain() {
      return (v) => {
        return /^[a-zA-Z0-9\-\\_]+$/.test(v);
      }
    },
  },
  mounted() {
    this.loadSubdomains();
  },
  methods: {
    openDomain(domain) {
      let a = document.createElement('a');
      a.href = 'https://' + domain;
      a.target = '_blank';
      a.click();

      a.remove();
    },

    copyDomain(domain) {
      let self = this;
      navigator.clipboard.writeText(domain).then(function () {
        self.$bvToast.toast(domain, {
          title: this.$t('generic.lang_domainCopied'),
          variant: 'info',
          solid: true
        })
      }, function (err) {
        this.$bvToast.toast(domain, {
          title: this.$t('generic.lang_domainCopied'),
          variant: 'danger',
          solid: true
        })
      });
    },
    loadSubdomains() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.SUBDOMAIN.GET, this.form).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          this.hasDomains = res.data.hasDomains;

          if (Array.isArray(res.data.domains) && res.data.domains.length > 0) {
            this.domains = [...res.data.domains];
          }
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    create() {
      if (!this.$refs.form.validate()) return;
      this.creation_loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.SUBDOMAIN.CREATE, this.form).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.$refs.form.reset();
          this.loadSubdomains();
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.creation_loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
