<template>
  <v-card elevation="0">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-switch :loading="this.translation_loader" :value="true" v-model="enable_translation"
                    :label="$t('settings.lang_enableMultiLang')"/>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-checkbox v-model="enableOrderbonTranslations" :label="$t('settings.lang_activateOrderReceiptTranslation')" true-value="1"
                    false-value="0"/>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-radio-group v-model="printingTranslationType" hide-details>
            <v-radio :label="$t('settings.lang_showTranslationText')" value="0">

            </v-radio>
            <v-radio :label="$t('settings.lang_showTranslationCanvas')" value="1">

            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pt-0">
      <v-spacer/>
      <v-btn @click="saveTranslationOptions" :loading="this.save_translation_loader || this.translation_loader"
             :disabled="this.save_translation_loader || this.translation_loader" depressed
             color="success">
        {{ this.$t("generic.lang_save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "TranslationOptionsComponent",
  data() {
    return {
      //translation
      enable_translation: false,
      enableOrderbonTranslations: '0',
      printingTranslationType: '0',
      save_translation_loader: false,
      translation_loader: false
    }
  },
  mounted() {
    this.getTranslationOptions();
  },
  methods: {
    getTranslationOptions() {
      this.translation_loader = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.OPTIONS.TRANSLATION.GET).then((res) => {
        if (res.data.success) {
          this.enable_translation = res.data.enable_translation;
          this.enableOrderbonTranslations = res.data.enableOrderbonTranslations;
          this.printingTranslationType = res.data.printingTranslationType;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.translation_loader = false;
      })
    },
    saveTranslationOptions() {
      this.save_translation_loader = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.OPTIONS.TRANSLATION.UPDATE,{
        enable_translation:this.enable_translation,
        enableOrderbonTranslations:this.enableOrderbonTranslations,
        printingTranslationType:this.printingTranslationType,
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.save_translation_loader = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
