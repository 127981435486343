<template>
  <div
    :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
    class="main-card card"
  >
    <div
      :class="[this.$vuetify.theme.dark ? 'white--text' : 'white']"
      class="card-header card-title card-header-tab"
    >
      <div
        class="card-header-title font-size-lg text-capitalize font-weight-normal"
      >
        {{ $t("settings.lang_erpSettings") }}
      </div>
    </div>

    <v-divider class="pa-0 ma-0" />

    <div>
      <v-progress-linear
        class="ma-0"
        color="primary"
        indeterminate
        v-if="this.loading"
      ></v-progress-linear>
    </div>

    <div>
      <div class="card-body">
        <v-checkbox
          v-if="this.$store.getters['permissions/checkModule'](27)"
          v-model="barcodeWithVarPriceWeight"
          :label="$t('erp.lang_enableBarcodeWeighPrices')"
        ></v-checkbox>
        <v-checkbox
          v-model="useNetPriceForItemData"
          :label="$t('erp.lang_useNetPriceForItemData')"
        ></v-checkbox>
        <v-checkbox
          v-model="systemDisplaySubtotal"
          :label="$t('erp.lang_displaySubtotal')"
        ></v-checkbox>
        <v-checkbox
          v-model="alterAdditionalBarcodePriceOnMainItemChange"
          :label="
            $t(
              'settings.lang_changeAdditionalBarcodePricesWhenItemPriceChanged'
            )
          "
        ></v-checkbox>
        <v-checkbox
          v-model="additionalBarcodeCalcPriceByAmount"
          :label="
            $t(
              'settings.lang_autoCalcSalePriceByBarcode'
            )
          "
        ></v-checkbox>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="success"
          @click="saveSettings"
        >
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "ErpSettingsComponent",

  data() {
    return {
      loading: false,
      barcodeWithVarPriceWeight: false,
      additionalBarcodeCalcPriceByAmount: false,
      useNetPriceForItemData: false,
      systemDisplaySubtotal: false,
      alterAdditionalBarcodePriceOnMainItemChange: false,
    };
  },

  mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.ERPSETTINGS.GET)
        .then((res) => {
          if (res.data.success) {
            this.barcodeWithVarPriceWeight = res.data.barcodeWithVarPriceWeight;
            this.useNetPriceForItemData = res.data.useNetPriceForItemData;
            this.systemDisplaySubtotal = res.data.systemDisplaySubtotal;
            this.alterAdditionalBarcodePriceOnMainItemChange = res.data.alterAdditionalBarcodePriceOnMainItemChange;
            this.additionalBarcodeCalcPriceByAmount = res.data.additionalBarcodeCalcPriceByAmount;
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveSettings() {
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.ERPSETTINGS.UPDATE,
          {
            barcodeWithVarPriceWeight: this.barcodeWithVarPriceWeight,
            useNetPriceForItemData: this.useNetPriceForItemData,
            systemDisplaySubtotal: this.systemDisplaySubtotal,
            alterAdditionalBarcodePriceOnMainItemChange: this.alterAdditionalBarcodePriceOnMainItemChange,
            additionalBarcodeCalcPriceByAmount: this.additionalBarcodeCalcPriceByAmount,
          }
        )
        .then((res) => {
          if (res.data.success) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
