<template>
    <v-container fluid>
        <v-row>
            <v-col class="pb-0" cols="12" md="4" sm="12">
                <div role="tablist">
                    <b-card class="mb-1" no-body>
                        <b-card-header :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'p-0']" header-tag="header" role="tab">
                            <v-list-item @click="globalSettings =! globalSettings" class="ma-0"
                                         v-b-toggle.global-settings>
                                <v-list-item-title :class="this.globalSettings? 'primary--text' : 'secondary--text'">
                                    {{ $t('generic.lang_nav_systemSettings') }}
                                </v-list-item-title>
                                <v-list-item-action>
                                    <v-icon v-if="!this.globalSettings">keyboard_arrow_down</v-icon>
                                    <v-icon v-if="this.globalSettings">keyboard_arrow_up</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </b-card-header>
                        <b-collapse :visible="this.globalSettings" accordion="global-settings" id="global-settings"
                                    role="tabpanel">
                            <b-card-body class="pa-0">
                                <b-list-group flush>
                                    <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'generalInfo'? 'primary--text' : '']"
                                                       @click="handleClick('generalInfo')"
                                                       class="items">{{ $t('generic.lang_kasse') }}
                                    </b-list-group-item>
                                    <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'logos'? 'primary--text' : '']"
                                                       @click="handleClick('logos')"
                                                       class="items">Logos
                                    </b-list-group-item>
                                    <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'recipe'? 'primary--text' : '']"
                                                       @click="handleClick('recipe')"
                                                       class="items">{{ $t('generic.lang_invoiceSettings') }}
                                    </b-list-group-item>
                                    <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'email'? 'primary--text' : '']"
                                                       @click="handleClick('email')"
                                                       class="items">{{ $t('settings.lang_emailSettings') }}
                                    </b-list-group-item>
                                    <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'payment'? 'primary--text' : '']"
                                                       @click="handleClick('payment')"
                                                       class="items">{{ $t('generic.lang_zahlungsoptionen') }}
                                    </b-list-group-item>
                                    <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'cashiers'? 'primary--text' : '']"
                                                       @click="handleClick('cashiers')"
                                                       class="items"> {{ $t('settings.lang_cashierOptions') }}
                                    </b-list-group-item>
                                    <b-list-group-item
                                        :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'globalcashieroptions'? 'primary--text' : '']"
                                        @click="handleClick('globalcashieroptions')"
                                        class="items"> {{ $t('generic.lang_globalCashierOptions') }}
                                    </b-list-group-item>
                                    <b-list-group-item
                                        :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'erpSettings'? 'primary--text' : '']"
                                        @click="handleClick('erpSettings')"
                                        class="items"> {{ $t('settings.lang_erpSettings') }}
                                    </b-list-group-item>
                                  <b-list-group-item
                                      :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'geolocationoptions'? 'primary--text' : '']"
                                      @click="handleClick('geolocationoptions')"
                                      class="items"> {{ $t('settings.lang_geolocationSettings') }}
                                  </b-list-group-item>

                                </b-list-group>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </v-col>

            <v-col cols="12" md="8" sm="12">

                <div v-if="currentTab === 'cashiers'">
                    <CashiersOptionsComponent/>
                </div>

                <div v-if="currentTab === 'generalInfo'">
                    <basic-data-component/>
                </div>


                <div v-if="currentTab === 'logos'">
                    <logos-component/>
                </div>

                <div v-if="currentTab === 'recipe'">
                    <RecipeSettingsComponent/>
                </div>
                <div v-if="currentTab === 'email'">
                    <EmailSettingsComponent/>
                </div>
                <div v-if="currentTab === 'payment'">
                    <PaymentSettingsComponent/>
                </div>
                <div v-if="currentTab === 'globalcashieroptions'">
                    <GlobalCashierOptions></GlobalCashierOptions>
                </div>
                <div v-if="currentTab === 'erpSettings'">
                    <ErpSettingsComponent></ErpSettingsComponent>
                </div>
              <div v-if="currentTab === 'geolocationoptions'">
                <GeoLocationOptions></GeoLocationOptions>
              </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config'
import mixin from "../../../mixins/KeyboardMixIns";
import Tabs from 'vue-tabs-with-active-line';
import BasicDataComponent from "./BasicDataComponent";
import LogosComponent from "./LogosComponent";
import RecipeSettingsComponent from "./receiptA4/RecipeSettingsComponent";
import EmailSettingsComponent from "./EmailSettingsComponent";
import PaymentSettingsComponent from "./Payment/PaymentSettingsComponent";
import CashiersOptionsComponent from "./CashiersOptionsComponent";
import GlobalCashierOptions from "@/components/settings/sysetmdata/GlobalCashierOptions";
import ErpSettingsComponent from '@/components/settings/sysetmdata/ErpSettingsComponent';
import GeoLocationOptions from "@/components/settings/sysetmdata/GeoLocationOptions";

export default {
    components: {
    GlobalCashierOptions,
    ErpSettingsComponent,
    CashiersOptionsComponent,
    PaymentSettingsComponent,
    EmailSettingsComponent,
    RecipeSettingsComponent,
    LogosComponent,
    BasicDataComponent,
    GeoLocationOptions,
    Tabs
},

    mixins: [mixin],

    data() {
        return {
            text: "example text",
            currentTab: 'generalInfo',
            ENDPOINTS,
            posName: null,
            taxID: null,
            streetNo: null,
            zip: null,
            city: null,
            country: null,
            globalSettings: true,
            itemCountries: [
                {name: this.$t("generic.lang_greatBritain"), id: "english"},
                {name: this.$t("generic.lang_germany"), id: "germany"},
                {name: this.$t("generic.lang_france"), id: "france"},
                {name: this.$t("generic.lang_swiss"), id: "swiss"}
            ],
            // ----------------------------------

        }
    },
    methods: {
        handleClick(newTab) {
            this.currentTab = newTab;
        },

    },
    computed: {
        tabs: function () {
            return [
                {
                title: this.$t('generic.lang_basicData'),
                value: 'generalInfo',
                },
                {
                title: 'Logos',
                value: 'logos',
                }
            ];
        }
    },
    mounted() {
        //GET DATA
        //this.getData();
    },
}
</script>

<style scoped>
.tabs > .tabs__item, .tabs {
    width: auto !important;
    z-index: 1 !important;
}

.tabs__item_active, .tabs__active-line, .shadow-tabs .tab-item-line {
    padding-top: 20px !important;
}

.tabs__item {
    z-index: 1 !important;
}

.tabs__item:hover {
    border: none;
    outline: none;
}

.tabs__item:focus {
    border: none;
    outline: none;
}

.items {
    cursor: pointer !important;
}

</style>
