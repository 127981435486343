<template>
  <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card card pa-0 ma-0">
    <div class="card-body pa-0">
      <b-tabs content-class="mt-3">
        <b-tab active :title="$t('settings.lang_receiptSetHeader')">
          <header-component/>
        </b-tab>
        <b-tab :title="$t('settings.lang_receiptSetFooter')">
          <RecipeFootersComponent/>
        </b-tab>
        <b-tab :title="$t('settings.lang_headerTextTempl')">
          <header-text-template-component/>
        </b-tab>
        <b-tab :title="$t('settings.lang_conditionTextTempl')">
          <conditions-text-template-component/>
        </b-tab>
        <b-tab :title="$t('generic.lang_deliveryTerms')+' '+$t('settings.lang_templates')">
          <delivery-terms-text-template-component/>
        </b-tab>
        <b-tab :title="$t('settings.lang_settings')" href="#settings">
          <settings-component/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import RecipeFootersComponent from "./RecipeFootersComponent";
import HeaderComponent from "@/components/settings/sysetmdata/receiptA4/HeaderComponent";
import SettingsComponent from "@/components/settings/sysetmdata/receiptA4/SettingsComponent";
import HeaderTextTemplateComponent from "@/components/settings/sysetmdata/receiptA4/HeaderTextTemplateComponent";
import ConditionsTextTemplateComponent
  from "@/components/settings/sysetmdata/receiptA4/ConditionsTextTemplateComponent";
import DeliveryTermsTextTemplateComponent from "./DeliveryTermsTextTemplateComponent";

export default {
  name: "RecipeSettingsComponent",
  components: {
    DeliveryTermsTextTemplateComponent,
    ConditionsTextTemplateComponent,
    HeaderTextTemplateComponent,
    SettingsComponent,
    HeaderComponent,
    RecipeFootersComponent
  },
}
</script>