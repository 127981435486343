<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <label>{{ $t('settings.lang_enableHeaderEl') }}:</label>
      </v-col>

      <v-col class="" cols="12" sm="6">
        <b-form-checkbox :value="1" class="" switch
                         v-model="form.displayInvoiceContactPerson">{{ $t('settings.lang_displayContactPerson') }}
        </b-form-checkbox>
      </v-col>

      <v-col class="" cols="12" sm="6">
        <b-form-checkbox :value="1" class="" switch
                         v-model="form.displayInvoiceDepartment">{{ $t('settings.lang_displayDepartment') }}
        </b-form-checkbox>
      </v-col>

      <v-col class="" cols="12" sm="6">
        <b-form-checkbox :value="1" class="" switch
                         v-model="form.displayInvoiceEmployeePhoneNumber">
          {{ $t('settings.lang_displayEmployeePhoneNumber') }}
        </b-form-checkbox>
      </v-col>

      <v-col class="" cols="12" sm="6">
        <p>{{$t('accounting.lang_receiptReceiptLogoAlign')}}</p>
        <v-btn-toggle active-class="primary" v-model="form.logo_align">
          <v-btn value="left">
            <v-icon>mdi-format-align-left</v-icon>
          </v-btn>

          <v-btn value="center">
            <v-icon>mdi-format-align-center</v-icon>
          </v-btn>

          <v-btn value="right">
            <v-icon>mdi-format-align-right</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12">
        <v-btn :disabled="this.loading" :loading="this.loading" @click="saveHeader" block
               class="mx-auto bg-primary text-light">{{
            this.$t('generic.lang_save')
          }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "HeaderComponent",
  data: () => {
    return {
      loading: false,
      form: {
        updatePart: "invoiceHeaderSettings",
        displayInvoiceContactPerson: 0,
        displayInvoiceDepartment: 0,
        displayInvoiceEmployeePhoneNumber: 0,
        logo_align: "left"
      }
    }
  },
  mounted() {
    this.loadHeader();
  },
  methods: {
    loadHeader() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.GET, {
        updatePart: "invoiceHeaderSettings"
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          this.form = res.data.header;
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    saveHeader() {
      this.loading = true;
      this.form.updatePart = "invoiceHeaderSettings"
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.UPDATE, this.form).then((res) => {

        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  padding-top: 6px !important;
}
</style>
