<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card elevation="0" outlined>
            <v-card-text class="ma-0 pa-0">
                <v-card elevation="0">
                    <v-card-title class="pa-0 ma-0">
                        <v-row>
                            <v-col class="pt-0 ma-0" cols="12">
                                <div class="card-header-tab card-header">
                                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <b-input-group width="100%">
                                            <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                          :placeholder="$t('datatables.lang_search')"
                                                          @focus="showTouchKeyboard"
                                                          v-model="search"/>
                                        </b-input-group>
                                    </div>
                                    <div class="btn-actions-pane-right actions-icon-btn">
                                        <v-btn @click="$router.push({name:'settings.basicSettings.mainSettings.payment.ecTerminal.create'})" class="white--text"
                                               color="info"
                                               elevation="1" fab small>
                                            <v-icon>
                                                mdi-plus
                                            </v-icon>
                                        </v-btn>
                                        <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
                                            <span slot="button-content"><i
                                                    class="pe-7s-menu btn-icon-wrapper"></i></span>
                                            <div>
                                                <h6 class="dropdown-header" tabindex="-1">
                                                    {{this.$t('generic.lang_Options')}}</h6>
                                                <button class="dropdown-item" tabindex="0" :disabled="selectedRows.length<1"
                                                        type="button" @click="deleteEntries"
                                                >
                                                    <v-icon class="dropdown-icon" color="red">
                                                        mdi-delete
                                                    </v-icon>
                                                    <span class="text-capitalize">{{ $t('generic.lang_delete') }}</span>
                                                </button>
                                            </div>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-data-table
                            :footer-props="footerProps"
                            :headers="filteredDatatableHeaders"
                            :items="filteredDatatableEntries"
                            :loading="loading"
                            :options.sync="pagination"
                            :server-items-length="total"
                            :show-select="true"
                            footer-props.items-per-page-text="Einträge pro Seite"
                            v-model="selectedRows"
                    >
                        <template v-slot:item.type="{ item }">
                        <v-row justify="center" align="center">
                            <v-col cols="12">
                                <v-avatar>
                                    <img v-if="(item.type==2)"
                                         :src="adyenLogo"
                                    />
                                  <img v-else-if="(item.type==3)"
                                       :src="ccvLogo"
                                  />
                                    <img v-else
                                         :src="zvtLogo"
                                    />
                                </v-avatar>
                            </v-col>
                        </v-row>
                    </template>
                        <template v-slot:item.register="{ item }">
                            <v-row v-if="(item.type==1)" justify="center" align="center">
                                <v-checkbox  readonly v-model="item.register" value="1">

                                </v-checkbox>
                            </v-row>
                        </template>
                        <template v-slot:item.printout="{ item }">
                            <v-row justify="center" align="center">
                                <v-checkbox readonly v-model="item.printout" value="1">
                                </v-checkbox>
                            </v-row>
                        </template>

                        <template v-slot:item.crud="{ item }">
                            <v-row justify="center" align="center">
                                <v-btn @click="editEntry(item)" class="mx-auto" color="primary" icon text>
                                    <v-icon>edit</v-icon>
                                </v-btn>

                                <v-btn @click="deleteEntry(item)" class="mx-auto" color="error" icon text>
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>

                <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                    <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                        :defaultKeySet="touchKeyboard.keySet"
                                        :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                        :options="touchKeyboard.options" class="internalWidthExpanded"
                                        id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../../config";
    import {Events} from "../../../../plugins/events";
    import moment from "moment";
    import mixin from "../../../../mixins/KeyboardMixIns";
    export default {
        name: "ECTerminalsComponent",
        mixins:[
            mixin,
        ],
        watch: {
            search: function () {
                clearTimeout(this.awaitingSearch);

                this.awaitingSearch = setTimeout(() => {
                    if (this.pagination.page !== 1) {
                        this.pagination.page = 1;
                    } else {
                        this.getDataFromApi();
                    }
                }, 500);
            },
            pagination: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            selectedRows: function () {
                this.$emit("changeSelectedRows", this.selectedRows);
            }
        },
        computed: {
            filteredDatatableHeaders: function () {
                let headers = [];

                this.datatableHeaders.forEach((header) => {
                    if (header.hasOwnProperty("hide")) {
                        if (header.hide) {
                            return;
                        }
                    }
                    headers.push(header);
                });
                return headers;
            },
            filteredDatatableEntries: function () {
                let dataToRender = [];

                this.dataToShow.forEach((data) => {
                    let row = {};

                    this.datatableHeaders.forEach((header, index) => {
                        if (!(header.hidden && header.hidden == true)) {
                            row[header.value] = data[index];

                            if(header.value==="status"){
                                if(row[header.value]=="2"){
                                    row[header.value]=true;
                                }else{
                                    row[header.value]=false;
                                }
                            }
                            if(header.value==="startDate"){
                                row[header.value]=moment(data[index],"hh:mm:ss DD loc.MM.YY").format('YYYY-MM-DD')
                            }
                        }

                    });

                    dataToRender.push(row);
                });

                return dataToRender;
            }
        },
        data(){
            return{
                loading:false,
                dialog:false,
                zvtLogo:require("@/assets/images/ecterminal/ZVT.svg"),
                adyenLogo:require("@/assets/images/ecterminal/adyen-logo-green.svg"),
                ccvLogo:require("@/assets/images/ecterminal/ccv.svg"),
                mode:"create",
                id:null,
                awaitingSearch:null,
                selectedRows:[],
                dataToShow: [],
                search: '',
                total: 0,
                datatableHeaders:[
                    {
                        text: 'Terminal ID',
                        value: 'id',
                        align: 'start',
                        hide:true,
                    },
                    {
                        text: this.$t('generic.lang_alias'),
                        value: "aliasName",
                        align: 'start',
                    },
                    {
                        text: this.$t('generic.lang_cashierID'),
                        value: 'cashierID',
                        align: 'start',
                    },
                    {
                        text: this.$t('settings.lang_terminalType'),
                        value: 'type',
                        align: 'center',
                    },
                    {
                        text: this.$t('generic.lang_ipAddress'),
                        value: 'ip',
                        align: 'start',
                    },
                    {
                        text: this.$t('generic.lang_port'),
                        value: 'port',
                        align: 'start',
                    },
                    {
                        text: this.$t('generic.lang_needsRegistration'),
                        value: 'register',
                        align: 'center',
                    },
                    {
                        text: this.$t('settings.lang_ecZvtPrintOverCashierPrinter'),
                        value: 'printout',
                        align: 'center',
                    },
                    {
                        text: '',
                        value: 'crud',
                        sortable: false,
                        width: '230',
                        align: 'end',
                    }
                ],
                pagination: {},
                footerProps: {'items-per-page-options': [15, 30, 50, 100]},
                rowsPerPageItems: [10, 20, 50, 100],
            }
        },
        methods:{
            editEntry(entry){
                if(entry.type == 1){
                    this.$router.push({name:'settings.basicSettings.mainSettings.payment.ecTerminal.edit',params:{id:parseInt(entry.id)}})
                }else if(entry.type == 2){
                    this.$router.push({name:'settings.basicSettings.mainSettings.payment.adyenterminal.edit',params:{id:parseInt(entry.id)}})
                }
                else{
                this.$router.push({name:'settings.basicSettings.mainSettings.payment.ccvterminal.edit',params:{id:parseInt(entry.id)}})
              }
            },
            deleteEntries(){
                let ids=[]
                this.selectedRows.forEach((row) => {
                    ids.push(row.id);
                });
                this.deleteData(ids);
            },
            deleteEntry(entry){
                this.deleteData([parseInt(entry.id)])
            },
            deleteData(idsToDelete=[]){
                let self = this;
                this.loading=true;
                this.$swal({
                    title: this.$t('generic.lang_deleteProject'),
                    text: this.$t('generic.lang_deleteConfirmationMSG')+'?',
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(this.id);
                        }
                        this.axios.post('delete/settings/ecTerminal/', {
                            ecTerminalIDs: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('settings.lang_configsDeleted'),
                                    color: "success"
                                });
                                self.getDataFromApi();
                                self.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                            this.dialog=false;
                            this.loading=false;
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                });
            },
            getDataFromApi(){

                this.loading = true;
                this.selectedRows = [];

                let params = {
                    sEcho: 1,
                    iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
                    iDisplayLength: this.pagination.itemsPerPage
                };

                //SORTING / PAGINATION / SEARCHING
                if (this.search) {
                    params.sSearch = this.search;
                }

                if (this.pagination.sortBy) {
                    //FIND COL. INDEX
                    let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);

                    if (index < 0) {
                        index = 0;
                    }

                    params["iSortingCols"] = 1;
                    params["iSortCol_0"] = index;
                    params["bSortable_" + index] = "true";
                    params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
                }


                this.axios.get("datatables/settings/ecTerminals/", {
                    params: params
                }).then((res) => {
                    this.total = parseInt(res.data.iTotalDisplayRecords);
                    //let data=res.data.aaData;
                    this.dataToShow = res.data.aaData;
                    this.loading = false;
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });

                    this.dataToShow = [];
                });
            },
            resetSelectedRows() {
                this.selectedRows = [];
            },
        },
        mounted() {
            this.getDataFromApi();
        }
    }
</script>

<style scoped>

</style>