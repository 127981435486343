<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-header transparent">
        {{$t('settings.lang_cashierOptions')}}
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <Datatable ref="alias" :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.ALIAS" :datatable-headers="this.headers"
                   :elevation="0"
                   no-excel-export
                   show-edit-buttons
                   @editEntry="editEntry"/>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                          :layout="touchKeyboard.layout" :options="touchKeyboard.options"/>
    </div>
  </v-container>
</template>

<script>

import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns"

export default {
  name: "CashiersOptionsComponent",
  mixins: [mixin],
  components: {Datatable},
  data: () => {
    return {
      ENDPOINTS,
      loading: false,
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: "id",
          value: "id",
          hide: true
        },
        {
          text: this.$t('generic.lang_identifier'),
          value: "identifier",
        },
        {
          text: this.$t('generic.lang_alias'),
          value: "aliasName",
        },
        {
          text: "Note",
          value: "notes",
          hide: true
        }
      ];
    }
  },
  methods: {
    editEntry(entry){
      this.$router.push({name: 'settings.basicSettings.cahieroptions.edit', params: {id:entry.id}})
    }
  }
}
</script>

<style scoped>

</style>